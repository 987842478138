import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import Header from './Layout/Header'
import Footer from './Layout/Footer'
import Catering from './pages/Catering'
import Products from './pages/Products'
import Orders from './pages/Orders'
import Signin from './pages/Signin'
import Signup from './pages/Signup'
import ForgotPassword from './pages/ForgotPassword' 
import CustomerProfile from './pages/CustomerProfile' 
import PageNotFound from './pages/PageNotFound' 


class Routes extends React.Component{
    render(){
        return(
            <React.Fragment>
            {/* {window.location.pathname ==='/login' || window.location.pathname ==='/signup' ?<></>: <Header />} */}
               <Router>
                 <Header />
                 <Switch>
                   <Route exact path="/" component={Catering} />
                  <Route exact path="/login" component={Signin} />
                   <Route exact path="/signup" component={Signup} />
                   <Route exact path="/forgotPassword" component={ForgotPassword} />
                   <Route exact path="/profile" component={CustomerProfile} />
                  
                   <Route exact path="/orders" component={Orders} />
                   <Route exact path="/products" component={Products} />
                   <Route exact path="/carts/:cartToken" component={Products} />
                   <Route component={PageNotFound}/>
                 </Switch>
                 <Footer />
               </Router>
               {/* {window.location.pathname ==='/login' || window.location.pathname ==='/signup' ?<></>: <Footer />} */}
         </React.Fragment>
        )
    }
}

export default Routes