import axios from 'axios'
const  BASE_URL ="https://ibarslider.sofmen.com/rest/";


const Api = axios.create({
    baseURL: BASE_URL
});

Api.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        console.log('unauthorized, logging out ...');
        localStorage.removeItem('customerToken')
        window.location.reload()
    }
    return Promise.reject(error.response);
});

Api.interceptors.request.use(function (config) {
    var token= localStorage.getItem('customerToken')
    if(token!==undefined && token !==null){
        config.headers.common['Authorization'] = `Bearer ${token}`;
        config.headers.common['Content-Type'] = 'application/json';
        //config.headers.common['Id-Token'] = token.id_token;   
    }
    
    return config;
  });

export default Api