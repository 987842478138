import React from 'react';
import { connect } from 'react-redux'
import logo from '../assets/images/logo.png'
import { getCustomerToken } from '../stores/actions/customer.action'
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'

class Signin extends React.Component {

	state = {
		fields: {},
		errors: {}
	}
	handleValidation = () => {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		//Email
		if (!fields["email"]) {
			console.log("got it...");
			formIsValid = false;
			errors["email"] = "*Email is required";
		}

		if (typeof fields["email"] !== "undefined") {
			let lastAtPos = fields["email"].lastIndexOf('@');
			let lastDotPos = fields["email"].lastIndexOf('.');
			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
				formIsValid = false;
				errors["email"] = "*Email is not valid";
			}
		}

		//Password
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Password is required.";
		}
		this.setState({ errors: errors });
		return formIsValid;
	}
	handleChange(field, e) {

		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	}

	requestToLogin = (e) => {
		e.preventDefault();
		if (this.handleValidation()) {
			let CustomerData = { ...this.state.fields }
			let data = {
				username: CustomerData.email,
				password: CustomerData.password
			}
			this.props.getCustomerToken(data)
		}

	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.customerToken !== undefined) {
			//window.location.href='/'
			this.props.history.push('/')
		}
	}
	componentDidMount() {
		let temp = localStorage.getItem('customerToken')
		if (temp !== null) {
			this.props.history.push('/')
		}

	}
	render() {
		return (
			<div className="login-wrap">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-7 m-auto">
							<div className="card card-box">
								<div className="card-head" style={{ 'backgroundColor': '#343a40', 'padding': '15px 10px', 'textAlign': 'center' }}>
									<div className="text-center text-uppercase text-light"><h4>Login</h4></div>
								</div>
								<div className="card-body ">
									<form method="post" name="signinform" className="signinform" >
										<div className="row">

											<div className="col-md-12 mt-4">
												<div className="form-group">
													<label>Email</label>
													<input type="email" name="email" className="form-control" placeholder="Email" onChange={this.handleChange.bind(this, "email")} defaultValue={this.state.fields["email"]} />
													<span className="error" style={{ color: "red" }}>{this.state.errors["email"]}</span>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label>Password</label>
													<input type="password" name="password" className="form-control" placeholder="********" onChange={this.handleChange.bind(this, "password")} defaultValue={this.state.fields["password"]} />
													<span className="error" style={{ color: "red" }}>{this.state.errors["password"]}</span>
												</div>
											</div>
											<div className="col-6">
												<div className="form-group mt-3">
													<input type="checkbox" id="remember" className="filter-check" />
													<label htmlFor="remember" className="check">Remember me?</label>
												</div>
											</div>
											<div className="col-6">
												<div className="text-right mt-3">
													<Link to="/forgotPassword">Forgot Password?</Link>

												</div>
											</div>
											<div className="col-md-12 ">
												<Link to="/signup">Create New Account</Link>
											</div>
											<div className="col-md-12 ">
												{this.props.customerToken === undefined && this.props.error !== undefined && <span className="error" style={{ color: "red" }}>{this.props.error}</span>}
											</div>
											<div className="col-md-12 mt-3">
												<div className=""><button className="button btn btn-lg btn-block d-block" onClick={(e) => this.requestToLogin(e)}>Submit</button>

												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		customerToken: state.customerToken,
		error: state.error
	}

}
const mapDispatchToProps = (dispatch) => {
	return {
		getCustomerToken: bindActionCreators(getCustomerToken, dispatch),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Signin)