import React from 'react';
import Logo from '../assets/images/logo.png'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

class Header extends React.Component{
  state={
    token:null
  }
  removeStorage(){
    localStorage.removeItem('customerToken')
    localStorage.removeItem('cartId')
    this.setState({token:null})
  }
  componentWillReceiveProps(nextProps){
    
    if(nextProps.customerToken!==undefined && this.state.token!==nextProps.customerToken){
      this.setState({token:nextProps.customerToken})
    }
  }
  componentDidMount(){
    let temp= localStorage.getItem('customerToken');
    this.setState({token:temp})
  }
  render(){
    const {customerInfo}= this.props
    return(
      // <!-- Navigation -->
      <header className="header-wrap">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
          <Link to='/' className="nav-link"><img src={Logo} className="img-fluid" alt="Logo"/></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span className="simple-icon-menu"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item">
                  <a className="nav-link" href="/">Call us(787) 987-6545
                  </a>
                </li> */}
                <li className="nav-item">
                <Link to='/' className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
            <Link to='products' className="nav-link">Products</Link>
            
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" href="#">Menu 2</a>
            </li> */}
           
                {this.state.token!==undefined && this.state.token!==null?<>
                  <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {customerInfo!==undefined && customerInfo.firstname}
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <Link to='profile' className="dropdown-item" >Profile</Link>
                <div className="dropdown-divider"></div>
                <Link to='orders' className="dropdown-item" >Orders</Link>
                <div className="dropdown-divider"></div>
                <Link to='login' className="dropdown-item" onClick={()=>this.removeStorage()} >Sign Out</Link>
              </div>
            </li>
                </>:<>
                <li className="nav-item">
                  <Link to='login' className="nav-link" >Sign in</Link>
                </li>
                <li className="nav-item">
                <Link to='signup' className="nav-link">Sign up</Link>
                 
                </li>
                </>
               }
               <li className="nav-item">
                  <div className="nav-link sign-up text-light"  style={{border: '1px solid #E54C2A', color: '#E54C2A'}}>Call us(787) 987-6545</div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}
const mapStateToProps=(state)=>{
  return{
          customerInfo:state.customerInfo,
          customerToken:state.customerToken,
          customerInfoError: state.customerInfoError
      }
 
}
export default connect(mapStateToProps)(Header);
