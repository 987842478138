import React from 'react'
import {Link} from 'react-router-dom'
export const PickupForm =({customer,onHandleChange})=>{
      let d = new Date();
      let today = d.getFullYear()+'-'+("0" + (d.getMonth() + 1)).slice(-2) +'-'+("0" + d.getDate()).slice(-2);
      let cTime=("0" + d.getHours()).slice(-2)+':'+("0" + d.getMinutes()).slice(-2)+':'+("0" + d.getSeconds()).slice(-2)
     
     
    return(
    <form>
            <div className="pick-up-addr">
          <div className="card card-box">
            <div className="card-body">
              <div className="mb-2"><i className="simple-icon-location-pin mr-2"></i> 324 University Avenue, Palo Alto, CA 94301</div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date</label>
                    <input type="date" name="date" className="form-control" onChange={(e)=>onHandleChange(e)} defaultValue={customer.date!==undefined?customer.date:today} min ={today}/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Time</label>
                    <input type="time" name="time" className="form-control"onChange={(e)=>onHandleChange(e)} defaultValue={customer.time!==undefined?customer.time:cTime}/>
                  </div>
                </div>
              </div>
              {localStorage.getItem('customerToken')===null?
              <div className="col-md-12">
                  <div className="text-center">
                    <span><strong>or</strong></span>
                  </div>
                  <div className="text-center mt-2">
                     <a href="/signup"><u>Sign in to the account</u></a>
                  </div>
                </div>
                :''}
              <div className="mt-4">
              <div className=""><Link to={{pathname:'/products',customer:customer}} className="button d-block">Continue</Link></div>
              </div>
            </div>
          </div>
        </div>
        </form>
        
        )
}