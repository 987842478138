import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js' 
import 'bootstrap/dist/js/bootstrap.min.js'

import {Provider} from 'react-redux'
import {createStore,applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import reducers from './stores/reducers'

import App from './App';


import * as serviceWorker from './serviceWorker';

const store = createStore(reducers,applyMiddleware(thunk,logger))

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
