import React from 'react'

export const TopCategory = ({categories}) => {
    return (
        <section className="m-0 category-hz">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="category-btn">
                            {categories.children_data!==undefined && categories.children_data.length && categories.children_data.map((item, index) => {
                                return (
                                    <a key={item.id} className={item.name.toLowerCase()} href={`#${item.name.toLowerCase()}`}>{item.name} </a>
                                )
                            })

                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export const AllFilters=({attributesData,filterProduct})=>{
    return(
        <section className="filter-hz">
        <div className="container">
          <div className="row">
            <div className="col-md-12 item-filter">
             
              <a className="button-sm" data-toggle="collapse" href="#home" role="button" aria-expanded="false" aria-controls="sliders">ALL FILTERS</a>
              <div className="search-by-filter collapse" id="home">
                <div className="filter" >
                  {attributesData.length > 0 && attributesData.map((item,index)=>{
                    return(
                      <div className="filter-child" key={index}>
                    <h3>{item.label}</h3>
                      <ul>
                        {
                          item.value.length && item.value.map((subitem,index)=>{
                            return(
                              <li key={subitem.value}>
                              <div className="form-group mt-3">
                                <input type="checkbox" id={subitem.value} className="filter-check" value={subitem.option_id} onChange={(e)=>filterProduct(e)} name={`${item.code}_${subitem.option_id}`}/>
                                <label htmlFor={subitem.value}>{subitem.value}</label>
                              </div>
                            </li>
                            )
                          })
                  }
                      </ul>
                    </div>
                    )
                  })
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}