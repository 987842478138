import React from 'react'
import deliveryTruck from '../assets/images/delivery-truck.png'



class ForgotPassword extends React.Component{
    render(){
        return(
            <div className="text-center m-5">
            <h2>Sorry! Page Not Found</h2>
            <img src={deliveryTruck} alt="shop"/>
            </div>
        )
    }
}

export default ForgotPassword