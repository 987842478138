import React from 'react'
import {forgotPassword} from '../services/customer'

class ForgotPassword extends React.Component{
    state={
        email:'',
        error:''
    }
    requestToResetLink=(e)=>{
       var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        
        if(this.state.email!=='' && re.test(this.state.email)){
           let temp={
            "email": this.state.email,
            "template" : "email_reset",
            "websiteId": 1
           }
            forgotPassword(temp).then(res=>{
                if(res){}else{
                    this.setState({error:"Please Try after some time"})    
                }

            }).catch(err=>console.log(err))
            
        }else{
            this.setState({error:"Invalid Email"})
        }
    }
    render(){
        return(
            <div className="login-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-7 m-auto">
                        <div className="card card-box">
                            <div className="card-head" style={{'backgroundColor':'#343a40','padding':'15px 10px','textAlign':'center'}}>
                                    <div className="text-center text-uppercase text-light"><h4>Forgot Password</h4></div>
                            </div>
                            <div className="card-body ">
                                
                                    <div className="row">
                                        
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" name="email" className="form-control" placeholder="Email" onChange={(e)=>this.setState({email:e.target.value})} defaultValue={this.state.email} />
                                            <span style={{color: "red"}}>{this.state.error}</span>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                          <div className="">
                                              <button  className="button btn btn-lg btn-block  d-block" onClick={(e)=>this.requestToResetLink(e)}>Send Reset Link</button>
                                          
                                          </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default ForgotPassword