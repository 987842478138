import React from 'react';
import {Link} from 'react-router-dom'

class DeliveryForm extends React.Component{
    
    render(){
      let {customer} = this.props
      let d = new Date();
      let mm = ("0" + (d.getMonth() + 1)).slice(-2);
      let dd = ("0" + d.getDate()).slice(-2);
      let today = d.getFullYear()+'-'+mm +'-'+dd;
      let hh=("0" + d.getHours()).slice(-2);
      let m=("0" + d.getMinutes()).slice(-2);
      let s=("0" + d.getSeconds()).slice(-2);
      let cTime=hh+':'+m+':'+s
      return(
        <form>
        <div className="delivery-opt-form">
          <div className="card card-box">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Enter Address</label>
                    <input type="text" name="address" className="form-control" onChange={(e)=>this.props.onHandleChange(e)} defaultValue={customer.address!==undefined?customer.address:''}/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date</label>
                    <input type="date" name="date" className="form-control" onChange={(e)=>this.props.onHandleChange(e)} value={customer.date!==undefined?customer.date:today} min={today}/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Time</label>
                    <input type="time" name="time" className="form-control" step={2} onChange={(e)=>this.props.onHandleChange(e)} value={customer.time!==undefined?customer.time:cTime}/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" name="email" className="form-control" placeholder="email" onChange={(e)=>this.props.onHandleChange(e)} value={customer.email!==undefined?customer.email:''}/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Number of Guest</label>
                    <input type="number" name="noGuest" className="form-control" onChange={(e)=>this.props.onHandleChange(e)} defaultValue={customer.noGuest!==undefined?customer.noGuest:1}/>
                  </div>
                </div>
                {localStorage.getItem('customerToken')===null?
                <div className="col-md-12">
                  <div className="text-center">
                    <span ><strong>or</strong></span>
                  </div>
                  <div className="text-center mt-2">
                    <a href="/login"><u>Sign in to the account</u></a>
                  </div>
                </div>
                 :''}
                <div className="col-md-12">
                  <div className=""><Link to={{pathname:'/products',customer:customer}} className="button d-block">Continue</Link></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </form>
      )
    }
  }
  
  export default DeliveryForm;