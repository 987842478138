import Api from '../Api'


export const getCartId=()=>{
    Api.post('SBV/V1/carts/mine')
    .then(res=>localStorage.setItem('cartId',res.data))
    .catch(err=>console.log("customer cart Id",err))
}

export const addToCart=(cartItems)=>{
   return  Api.post('SBV/V1/carts/mine/items',cartItems)
    .then(res=>res.data)
    .catch(err=>console.log("add item in cart",err))
}

export const cartList=(token)=>{
    return  Api.get(`SBV/V1/carts/mine/items`)
     .then(res=>res.data)
     .catch(err=>console.log("cart list",err))
 }
export const shipingMethods=(data)=>{
    return  Api.post(`SBV/V1/carts/mine/estimate-shipping-methods`,data)
    .then(res=>res.data)
    .catch(err=>console.log("cart list",err))  
}
export const shipingInfo=(data)=>{
    return  Api.post(`SBV/V1/carts/mine/shipping-information`,data)
    .then(res=>res.data)
    .catch(err=>console.log("cart list",err))  
}

 export const paymentInfo=(data)=>{
    return  Api.post(`SBV/V1/carts/mine/payment-information`,data)
     .then(res=>res.data)
     .catch(err=>console.log("cart list",err))
 }

export const createNewOrder=(cartId,data)=>{
    return  Api.put(`V1/carts/mine/order`,data)
    .then(res=>res.data)
    .catch(err=>console.log("order service",err))
}

export const getAttributes=()=>{
    return  Api.get(`V1/getattributes`)
     .then(res=>res.data)
     .catch(err=>console.log("Attributes err",err))
}

export const commentOnOrder=(orderId,data)=>{
    return  Api.post(`V1/orders/${orderId}/comments`,data)
    // .then(res=>res.data)
    // .catch(err=>console.log("order service",err))
}

export const forgotPassword=(data)=>{
    return Api.put(`V1/customers/password`,data).then(res=>res.data).catch(err=>err.data)
}
