import React from 'react';
import logo from '../assets/images/logo.png'
import { createCustomer } from '../stores/actions/customer.action'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'


class Signup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {},
      errors: {},
      isDisabled: true
    }
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //First Name
    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = "*First Name is required.";
    }

    if (typeof fields["firstname"] !== "undefined") {
      if (!fields["firstname"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["firstname"] = "First Name must contain only letters";
      }
    }

    //Last Name
    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = "*Last Name is required.";
    }

    if (typeof fields["lastname"] !== "undefined") {
      if (!fields["lastname"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["lastname"] = "Last Name must contain only letters";
      }
    }

    //Phone No
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Phone No. is required.";
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Email is required.";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf('@');
      let lastDotPos = fields["email"].lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    //Password
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Password is required.";
    }
    if (typeof fields["password"] !== "undefined") {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(fields["password"]);
      if (!strongRegex) {
        formIsValid = false;
        errors["password"] = "Password contains at least 1 lowercase ,1 uppercase ,1 numeric,one special character and eight characters in length.";
      }
    }

    //Confirm Password
    if (!fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "*Confirm Password is required.";
    }
    if (typeof fields["confirmPassword"] !== "undefined") {
      if (fields["password"] !== fields["confirmPassword"]) {
        formIsValid = false;
        errors["confirmPassword"] = "*Password Do not Match";
      }
    }

    //Address
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "*Street is required.";
    }

    //State
    if (!fields["state"]) {
      formIsValid = false;
      errors["state"] = "*State is required.";
    }

    //City
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "*City is required.";
    }

    //Zip
    if (!fields["zip"]) {
      formIsValid = false;
      errors["zip"] = "*Zip is required.";
    }


    this.setState({ errors: errors });
    return formIsValid;
  }

  signupSubmit(e) {
    let newCustomerData = { ...this.state.fields }
    console.log(newCustomerData.firstname)
    e.preventDefault();
    if (this.handleValidation()) {
      console.log(newCustomerData)
      this.props.createCustomer(newCustomerData)
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.newCustomerAdded !== undefined && nextProps.newCustomerAdded > 0) {
      window.location.href = '/login'
    }
    if (nextProps.newCustomerError !== undefined) {
      this.setState({ error: true, errorMessage: nextProps.newCustomerError })
    }
  }
  render() {
    return (
      <div className="login-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-10 m-auto">
              <div className="card card-box">
                <div className="card-head" style={{ 'backgroundColor': '#343a40', 'padding': '15px 10px', 'textAlign': 'center' }}>
                  <div className="text-center text-uppercase text-light"><h4>Sign Up</h4></div>
                </div>
                <div className="card-body ">
                  <form method="post" name="signupform" className="signupform" onSubmit={this.signupSubmit.bind(this)}>
                    <div className="row">

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input type="text" name="firstname" className="form-control" placeholder="First Name" onChange={this.handleChange.bind(this, "firstname")} value={this.state.fields["firstname"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input type="text" name="lastname" className="form-control" placeholder="Last Name" onChange={this.handleChange.bind(this, "lastname")} value={this.state.fields["lastname"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone No.</label>
                          <input type="number" name="phone" className="form-control" placeholder="(000) 000-0000" onChange={this.handleChange.bind(this, "phone")} value={this.state.fields["phone"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["phone"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input type="email" name="email" className="form-control" placeholder="@example.com" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["email"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Password</label>
                          <input type="password" name="password" className="form-control" placeholder="********" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["password"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Confirm Password</label>
                          <input type="password" name="confirmPassword" className="form-control" placeholder="********" onChange={this.handleChange.bind(this, "confirmPassword")} value={this.state.fields["confirmPassword"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["confirmPassword"]}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12"><h6>Address</h6></div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Street</label>
                          <input type="text" name="address" className="form-control" placeholder="" onChange={this.handleChange.bind(this, "address")} value={this.state.fields["address"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["address"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" name="city" className="form-control" placeholder="" onChange={this.handleChange.bind(this, "city")} value={this.state.fields["city"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["city"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>State</label>
                          <input type="text" name="state" className="form-control" placeholder="" onChange={this.handleChange.bind(this, "state")} value={this.state.fields["state"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["state"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Zip</label>
                          <input type="number" name="zip" className="form-control" placeholder="" onChange={this.handleChange.bind(this, "zip")} value={this.state.fields["zip"]} />
                          <span className="error" style={{ color: "red" }}>{this.state.errors["zip"]}</span>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        <Link to="/login">Already Account</Link><br />
                      </div>
                      <div className="col-md-12 ">
                        <span className="error" style={{ color: "red" }}>{this.state.errorMessage}</span>
                      </div>
                      <div className="col-md-12">
                        <div className=""><button type="submit" className="button btn btn-lg btn-block d-block" id="submit" >Save</button></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    newCustomerAdded: state.newCustomerAdded,
    newCustomerError: state.newCustomerError
  }

}
const mapDispatchToProps = (dispatch) => {
  return {
    createCustomer: bindActionCreators(createCustomer, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Signup)