
export default (state={},action)=>{
    switch(action.type){
        case 'customer_created':
            return Object.assign({}, state, {
                newCustomerAdded: action.payload.id
              }); 
        case 'customer_created_error':
                return Object.assign({}, state, {
                    newCustomerError: action.payload
                  });  
        case 'customer_token':
                localStorage.setItem('customerToken',action.payload)
                return Object.assign({}, state, {
                    customerToken: action.payload
                  });  
        case 'customer_token_failed':
           return Object.assign({}, state, {
                        error: action.payload.status!==undefined && action.payload.status===401?"Invalid User":'Please Try Again'
                     });  
        case 'customer_info':
            return Object.assign({}, state, {
                            customerInfo: action.payload
                }); 
        case 'customer_info_failed':
            return Object.assign({}, state, {
                customerInfoError: action.payload!==undefined && action.payload.status===401?"Not able to get UserInfo":'Please Try Again'
             }); 
        default:
            return state;
    }
}
