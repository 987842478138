import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import defaultImage from '../assets/images/default.png'
import Cart from './Cart'
import { Modal } from 'react-bootstrap';
import {getAttrValue} from '../Helper'
import {getCartId} from '../services/customer'


const SingleCat = ({ products, indKey, showModalPopup }) => {
    return (
        <div className="filter-item-result" key={indKey.toString()}>
            {products!==undefined && products.length && products.map(item => {
                let details=''
                let shortDetails=''
                let thumb=''
                if(item.custom_attributes!==undefined && item.custom_attributes.length){
                details=getAttrValue(item.custom_attributes,'description')
                 shortDetails=getAttrValue(item.custom_attributes,'short_description')
                 thumb=getAttrValue(item.custom_attributes,'thumbnail')
                }
                
             
                return (
                    <div className="items" key={item.id}>
                        <div className="item-img">
                            <img src={thumb!==undefined && thumb?`https://ibarslider.sofmen.com/pub/media/catalog/product${thumb}`:defaultImage} className="img-fluid" alt="" />
                        </div>
                        <div className="item-detail">
                            <h4>
                                <a href="#" data-toggle="modal" data-target="#itemDetail" onClick={() => showModalPopup(item)}>{item.name}</a>
                            </h4>
                            <span className="amount">{`$${item.price}`}</span>
                            <p className="item-detail-content">{details !== undefined && ReactHtmlParser(details)}</p>
                            <div className="item-footer">
                                <span className="mr-1"><strong></strong></span>
                                <span className="f-12 float-right">{shortDetails !== undefined && ReactHtmlParser(shortDetails.substring(0, 30))}</span>
                            </div>

                        </div>
                    </div>
                )
            })}

        </div>

    )
}

class ProductList extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowPoup: false,
            cartList:[],
            cartItem:{},
            total:0
        }
    }
    onHandleChange(e){
        let qTY= {...this.state.cartItem}
        //let total = this.state.total
        if(e.target.name.includes('subItem')){
            if(e.target.checked){
                qTY.price=qTY.price+parseInt(e.target.value)
              }else{
                qTY.price=qTY.price-parseInt(e.target.value) 
            }
        }
        if(e.target.name.includes('noOfQuantity')){
            qTY.qty=parseInt(e.target.value)
            
        }
        if(e.target.name.includes('specialInstruction')){
           qTY.specialInstruction=e.target.value
           
        }
        let total= this.state.cartList.reduce((t,item)=>{
            return t + Number((item.qty * item.price).toFixed(2))
        },0)
        if(e.target.name.includes('tipAmount')){
            
            total =total+(total*parseInt(e.target.value)/100)
           
        }
        if(e.target.name.includes('otherTipAmount')){
            
            total =parseInt(e.target.value)?total+parseInt(e.target.value):total
        }
        this.setState({total:Number(total.toFixed(2)),cartItem:qTY})
        
    }
    showModalPopup = (item) => {
        let temp=item
        temp.qty=1
        this.setState({ isShowPoup: true,cartItem:temp })
    }
    onHide = () => {
        this.setState({ isShowPoup: false })
    }
    addTocart(){
        let temp =[...this.state.cartList]
        
        let tmpIndex= temp.findIndex(cl=>cl.id===this.state.cartItem.id)
       
        if(tmpIndex >= 0){
            let prevQty=temp[tmpIndex].qty
            let tp= {...temp[tmpIndex],...this.state.cartItem}
            tp.qty = parseInt(tp.qty)+ parseInt(prevQty)
            temp[tmpIndex]=tp
            
        }else{
            temp.push(this.state.cartItem)
        }
        let total= temp.reduce((t,item)=>{
            return t + Number((item.qty * item.price).toFixed(2))
        },0)
       
        this.setState({ isShowPoup: false,cartList:temp,cartItem:{},total:total })
    }
    updateCart=(index,e)=>{
        let temp =[...this.state.cartList]
        if(e.target!==undefined && e.target.name.includes('qtyItem')){
            temp[index].qty=e.target.value
            
        }else{
            temp[index].qty=parseInt(temp[index].qty)+ parseInt(e)
            if(temp[index].qty===0){
               temp.splice(index,1)
            }
        }
        let total= this.state.cartList.reduce((t,item)=>{
            return t + Number((item.qty * item.price).toFixed(2))
        },0)
        this.setState({cartList:temp,total:total})
       
        
    }
    componentDidMount(){
        //store Quote Id
        if(localStorage.getItem('customerToken') !==null){
            getCartId() 
            if(localStorage.getItem('cartList')!==null){
                let temp= JSON.parse(localStorage.getItem('cartList'))
                localStorage.removeItem('cartList')
                this.setState({cartList:temp.cartList,total:temp.total,customer:temp.customer})
            }
        }
          
    }
    render() {
        const { categories, products } = this.props
       
        let details=''
        let product=''
        let thumb=''
        if(this.state.isShowPoup){
             product ={...this.state.cartItem}
             details=getAttrValue(product.custom_attributes,'description')
             //shortDetails=getAttrValue(product.custom_attributes,'short_description')
             thumb=getAttrValue(product.custom_attributes,'thumbnail') 
        }
       
        
        return (
            <section className="mb-5 mt-4">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-9 col-sm-12">
                            {categories.children_data !== undefined && categories.children_data.map((item, index) => {
                                if(products[item.name.toLowerCase()]!==undefined && products[item.name.toLowerCase()].length<1){
                                    return 
                                }
                                return (
                                
                                    <div id={item.name.toLowerCase()} key={item.id.toString()}>
                                        <h4>{item.name}</h4>
                                        <SingleCat products={products[item.name.toLowerCase()]} indKey={item.name.toLowerCase()} showModalPopup={this.showModalPopup} />
                                    </div>
                                    
                                )
                            })}

                        </div>
                        <Cart  propState={this.state} updateCart={this.updateCart} onHandleChange={(e)=>this.onHandleChange(e)} {...this.props}/>
                    </div>

                    <Modal show={this.state.isShowPoup}  onHide={this.onHide} keyboard={false} centered={true} backdrop="static">
                    <Modal.Header style={{height:'200px',backgroundSize:'cover',backgroundImage:`url(${thumb && `https://ibarslider.sofmen.com/pub/media/catalog/product${thumb}`})`}} closeButton>

                    </Modal.Header>
                       <Modal.Body  >
                        <h2 style={{color: '#E44C2A', FontSize: '24px', MarginBottom: '0px'}}>{product.name}</h2>
                        <h6>{`$${product.price}`}</h6>
                        <div className="mt-2">{ReactHtmlParser(details)}</div>
                         <div className="setect-qt">
                                <h6>Select Quantity:</h6>
                                <select className="form-control" onChange={(e)=>this.onHandleChange(e)} name="noOfQuantity">
                                    <option value='1'>1 item</option>
                                    <option value='2'>2 item</option>
                                    <option value='3'>3 item</option>
                                    <option value='4'>4 item</option>
                                    <option value='5'>5 item</option>
                                </select>
                                <span>will serve {5*this.state.cartItem.qty} people</span>
                        </div>
                        {product.options!==undefined ?product.options.filter(it=>it.product_sku===product.sku).map(itm=>{
                            return(
                                <div className="mt-3" key={itm.product_sku}>
                                    <h6>INSTEAD OF REGULAR BUNS, PLEASE SERVE:</h6>
                                    {itm.values.length && itm.values.map((sbitm,index)=>{
                                        return(
                                            <div className="form-check" key={index}>
                                            <label className="form-check-label" htmlFor="check1">
                                                <input type="checkbox" className="form-check-input"  name={`subItem${index}`} value={sbitm.price} onChange={(e)=>this.onHandleChange(e)}/>
                                                {sbitm.title}(+${sbitm.price})
                                            </label>
                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            )
                        }) 
                        
                        :''}
                        
                        <div className="form-group mt-3">
                                <h6>Add Special Inscruction:</h6>
                                <input type="text" name="specialInstruction" className="form-control" onChange={(e)=>this.onHandleChange(e)}/>
                            </div>
                            <div className="mt-3">
                                <button className="button btn btn-block btn-lg" data-dismiss="modal" onClick={()=>this.addTocart(product.sku)}>ADD TO CART 
                                    <small className="pull-right mr-3">${Number((product.price * this.state.cartItem.qty).toFixed(2))}</small>
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </section>
        )
    }
}
export default ProductList