import React from 'react'
import FontAwesome from 'react-fontawesome'
import 'font-awesome/css/font-awesome.min.css';
import { Modal } from 'react-bootstrap';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
//import DatePicker from "react-datepicker";
import {getCustomerInfo} from '../stores/actions/customer.action'
import {cartList,createNewOrder,paymentInfo,shipingMethods,shipingInfo,addToCart,commentOnOrder} from '../services/customer'

class Cart extends React.Component{
  constructor(props){
    super(props)
    this.state={
      reviewCartPoup:false,
      orderSuccess:false,
      shareCartPopup:false,
      wholeInstruction:'',
      ...props.propState
    }
  }
  componentWillReceiveProps(nextProps){
    this.setState({...nextProps.propState})
  }
  onShareCart=()=>{
    let enPopup='shareCartPopup'
    this.addItemToStorage(enPopup)
  }
  onHideReviewCart=()=>{
    this.setState({reviewCartPoup:false})
  }
  preCheckout=()=>{
    let enPopup='reviewCartPoup'
    this.addItemToStorage(enPopup)

  }
  addItemToStorage=(enPopup)=>{
    if(localStorage.getItem('customerToken')===null){
      if(this.props.propState.cartList.length>0){
        let temp={}
         temp.cartList =[...this.props.propState.cartList]
         temp.total = this.props.propState.total
         temp.customer =  this.props.location.customer
         localStorage.setItem('cartList',JSON.stringify(temp))
      }
     window.location.href='/login'
    }else{
      let d = new Date();
      let today = d.getFullYear()+'-'+("0" + (d.getMonth() + 1)).slice(-2) +'-'+("0" + d.getDate()).slice(-2);
      let cTime=("0" + d.getHours()).slice(-2)+':'+("0" + d.getMinutes()).slice(-2)+':'+("0" + d.getSeconds()).slice(-2)
      let customer = this.props.location.customer!==undefined?this.props.location.customer:{'orderType':'default','date':today,'time':cTime,'nonoGuest':1}
      let cartId = localStorage.getItem('cartId')
      let addedinCart=[]
      let wholeInstruction="Order Type:"+customer.orderType+"<br/> Date:"+customer.date+"<br/> Time:"+customer.time+"<br/> No.Of Guest:"+customer.nonoGuest
      this.props.propState.cartList.map((item)=>{
        let tmp ={};
        tmp.sku=item.sku
        tmp.qty=item.qty
        tmp.quote_id=cartId
        wholeInstruction= wholeInstruction + '<br/>'+ tmp.sku+':'+tmp.specialInstruction
        addToCart({cartItem:tmp}).then(res=>addedinCart.push(res.data)).catch(err=>console.log(err))
      })
    
      this.setState({[enPopup]:true,wholeInstruction:wholeInstruction})
    }
   
  }
  goCheckout=()=>{
    let customer = this.props.location.customer===undefined?{...this.props.customerInfo}:{...this.props.location.customer}
    let ship= customer.addresses.length >0 ? customer.addresses.find(add=>add.id==customer.default_shipping):{country_id:'US',postcode:'0',city:'NY',telephone:'999999999',street:['ABC'],region:{region:null,region_id:null,region_code:''}}
  

    let shipAddress= {  "address": {
      "region": ship.region.region,
      "region_id": ship.region.region_id,
      "region_code":ship.region.region_code,
      "country_id": ship.country_id,
      "street": customer.address===undefined?ship.street:[customer.address],
      "postcode": ship.postcode,
      "city": ship.city,
      "firstname": customer.firstname,
      "lastname": customer.lastname,
      "customer_id": customer.id,
      "email": customer.email,
      "telephone": ship.telephone,
      "same_as_billing": 1
  }
}

let shipInfo=
{  "addressInformation": {
      "shipping_address": shipAddress.address,
      "billing_address": shipAddress.address,
      "shipping_carrier_code": "flatrate",
      "shipping_method_code": "flatrate"
  }
}
let pay ={
  "paymentMethod": {
    "method": "cashondelivery"
  },
  "billing_address": shipAddress.address
}
  shipingMethods(shipAddress)
    .then(shm=>{
         console.log("shipAddress",shm.data)
         shipingInfo(shipInfo)
         .then(shIn=>{
          console.log("ship Info",shIn.data)
          paymentInfo(pay).then(payIn=>{
            console.info("Order Id",payIn.data)
            if(this.state.wholeInstruction!==''){
              let comment={
                "statusHistory": {
                    "comment": this.state.wholeInstruction,
                    "parent_id":payIn.data ,
                    "is_customer_notified": 0,
                    "is_visible_on_front": 1,
                    "status": "pending"
                }
            }
              commentOnOrder(payIn.data,comment).then(cmOrd=>console.log(cmOrd.data)).catch(err=>console.log(err))
            }
            this.setState({orderSuccess:true,reviewCartPoup:false})
            
          })
          .catch(err=>console.log(err))
         })
         .catch(err=>console.log(err))
    })
    .catch(err=>console.log(err))
  }
  componentDidMount(){
    let existingList=[]
    if(this.props.match.params.cartToken!==undefined && this.props.match.params.cartToken!==null){
      let excart=this.props.match.params.cartToken.split('-')
      localStorage.setItem('cartId',excart[0])
      localStorage.setItem('customerToken',excart[1])
      cartList().then(rt=>{
        console.log(rt)
        // existingList =
        // console.log(existingList)
      }).catch(err=>console.log(err))
      
    } 
    
    if(localStorage.getItem('customerToken') && this.props.customerInfo===undefined){
      this.props.getCustomerInfo()
    }
  }
  render(){
      let d = new Date();
      let today = d.getFullYear()+'-'+("0" + (d.getMonth() + 1)).slice(-2) +'-'+("0" + d.getDate()).slice(-2);
      let cTime=("0" + d.getHours()).slice(-2)+':'+("0" + d.getMinutes()).slice(-2)+':'+("0" + d.getSeconds()).slice(-2)
     
     const {onHandleChange } =this.props
     const {customer}=this.props.location
     const {cartList,total} = this.state
     let subTotal=total
        return(
            <div className="col-lg-3 col-sm-12">
            <div className="order-addr">
              <h4>Delivery Address</h4>
              <div className="row p-3">
  
                <div className="col-md-12 mb-1">
                  <label>Full Delivery Address</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <FontAwesome name="map-marker"/>
                        </span>
                    </div>
                    <input type="text" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" defaultValue={customer!==undefined && customer.address?customer.address:''}/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Delivery Date</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                      <FontAwesome name="calendar"/>
                          </span>
                    </div>
                    <input type="date" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" defaultValue={customer!==undefined && customer.date?customer.date:today}/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Delivery/Pickup Time</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                      <FontAwesome name="clock-o"/>
                          </span>
                    </div>
                    <input type="time" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" step={2} defaultValue={customer!==undefined && customer.time?customer.time:cTime}/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Headcount(EST)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                      <FontAwesome name="user-o"/>
                          </span>
                    </div>
                    <input type="text" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" defaultValue={customer!==undefined && customer.noGuest?customer.noGuest:5}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-cart" id="item-cart">
              <h4>Item Cart</h4>
              <ul>
                {cartList.length > 0 ? cartList.map((cart,index)=>{
                  //subTotal=subTotal+ Number((cart.qty * cart.price).toFixed(2))
                  return(
                    <li key={index}>
                    <div className="cart-order">
                      <div className="order-name">
                        {cart.name}
                      <div className="amount">${Number((cart.qty * cart.price).toFixed(2))}</div>
                      </div>
                      <div className="qua cart-qty">
                          <div className="input-group" style={{Width: '60px'}}>
                            <span className="input-group-btn">
                                <button className="btn btn-white btn-minuse" onClick={()=>this.props.updateCart(index,-1)}>-</button>
                            </span>
                            <input type="number" className="form-control no-padding add-color text-center" name="qtyItem" maxLength="3" value={cart.qty} onChange={(e)=>this.props.updateCart(index,e)}/>
                            <span className="input-group-btn">
                                <button className="btn btn-red btn-pluss" onClick={()=>this.props.updateCart(index,+1)}>+</button>
                            </span>
                        </div>
                      </div>
                      <div className="action">
                        {/* <!-- <a href="javascript:void(0)" className="mr-1" data-toggle="modal" data-target="#editDetail"><i className="simple-icon-pencil"></i></a> --> */}
                        <a href="#" className="">
                            <i className="simple-icon-close"></i>
                            </a>
                      </div>
                    </div>
                  </li>
                  )
                }):<p>Cart is empty.</p>}
              </ul>
              <div className="p-3">
                <a href="#" className="button-sm d-block mt-0" data-toggle="modal" data-target="#moreItems">Add Cutlery</a>
                {/* <a href="#" className="button-sm d-block mt-2" data-toggle="modal" data-target="#premiumDelivery">Add Premium Delivery</a> */}
              </div>
              <hr className="mt-0"/>
              {cartList.length > 0?<>
              <div className="d-flex justify-content-between px-3">
                <div>Delivery Fee</div>
                <div>$20.00</div>
              </div>
              <div className="d-flex justify-content-between px-3">
                <div>Sales Tax</div>
                <div>$5.00</div>
              </div>
              <hr/>
              <div className="d-flex justify-content-between px-3">
                <div>Tip for Driver</div>
                <div>$0.00</div>
              </div>
              <div className="px-3 mt-2 driver-tip">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" id="inline-radio1" type="radio" defaultValue="10" name="tipAmount" onClick={(e)=>onHandleChange(e)}/>
                  <label className="form-check-label" htmlFor="inline-radio1">
                    <span>10%</span>
                  </label>
                </div>
                <div className="form-check form-check-inline ">
                  <input className="form-check-input" id="inline-radio2" type="radio" defaultValue="15" name="tipAmount" onClick={(e)=>onHandleChange(e)}/>
                  <label className="form-check-label" htmlFor="inline-radio2"><span>15%</span></label>
                </div>
                <div className="form-check form-check-inline ">
                  <input className="form-check-input" id="inline-radio3" type="radio" defaultValue="20" name="tipAmount" onClick={(e)=>onHandleChange(e)}/>
                  <label className="form-check-label" htmlFor="inline-radio3"><span>20%</span></label>
                </div>
                <div className="form-check form-check-inline " style={{MarginRight: '-5px'}}>
                  <input className="custom-tip" id="inline-radio3" type="number" defaultValue="" name="otherTipAmount" placeholder="Other Amount" onChange={(e)=>onHandleChange(e)}/>
                </div>
              </div>
              <hr/>
              <div className="d-flex justify-content-between total px-3 mt-3">
                <div>Total</div>
              <div>${subTotal>0?Number(subTotal.toFixed(2))+20+5:0}</div>
              </div>
              {/* <div className="px-3">
              <button className="button-sm btn btn-block btn-lg" onClick={()=>this.onShareCart()}>Share Shopping Cart
              </button>
              </div> */}
              <div className="px-3 final-order">
                <button className="button-sm btn btn-block btn-lg" onClick={()=>this.preCheckout()}>Check Out</button>
              </div>
              </>:''}
            </div>
            {/* <div className="p-3 redeem-point ">
              <!-- <div><img src="images/amazon-gift.png"></div> -->
              <div className="">This order is worth xxx points</div>
            </div> */}
            <Modal className="" show={this.state.reviewCartPoup}  onHide={this.onHideReviewCart} keyboard={false} centered={true} backdrop="static">
            <Modal.Header closeButton>
              <h4>Have Enough Food for Everyone?</h4>
              </Modal.Header>
              <Modal.Body className="pt-0">
                {/* <div className="row"><div className="chechout-head">
                  <h6 className="mb-0">HEADCOUNT</h6></div></div> */}
                <div className="row ">
                  <div className="chechout-head">
                  <div className="col-md-4">QTY</div>
                  <div className="col-md-4">SERVES</div>
                  <div className="col-md-4">ITEM</div>
                  </div>
                </div>
                {/* <div className="row mt-2"><div className="chechout-head"><h6 className="mb-0">HEADCOUNT</h6></div></div> */}
                {cartList.map((ct,index)=>{
                  return(
                    <div className="row" key={index}>
                  <div className="col-md-4">{ct.qty}</div>
                        <div className="col-md-4">{ct.qty*5}</div>
                        <div className="col-md-4">{ct.name}</div>
                    </div>
                  )
                })}
               
                <div className="row">
                  <div className="col-md-4"><button className="btn-block button-sm-outline" style={{'border': '1px solid rgb(229, 76, 42)','color': 'rgb(229, 76, 42)','padding':'10px'}}onClick={this.onHideReviewCart}>Back To Menu</button></div>
                  <div className="col-md-8">
                    <button className="button-sm btn-block" onClick={()=>this.goCheckout()}>Proceed To Checkout</button></div>
                  
                  
                </div>
              </Modal.Body>
            </Modal>
             {/* Share Cart Popup*/}
             <Modal  show={this.state.shareCartPopup}  onHide={()=>this.setState({shareCartPopup:false})} keyboard={false} centered={true} backdrop="static">
            <Modal.Header closeButton><h4>Choose Sharing Option</h4></Modal.Header>
              <Modal.Body className="pt-0">
                
              <h5> Url: {`/carts/${localStorage.getItem('cartId')}-${localStorage.getItem('customerToken')}`}</h5>
                <button onClick={()=>this.setState({shareCartPopup:false})} className="btn btn-success">Share</button>
              </Modal.Body>
            </Modal>
            {/* Order is Successfull */}
            <Modal  show={this.state.orderSuccess}  onHide={()=>this.setState({orderSuccess:false})} keyboard={false} centered={true} backdrop="static">
            <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="pt-0">
                <h5>Order Successfully Placed.</h5>
                <button onClick={()=>this.setState({orderSuccess:false})} className="btn btn-success">Okay</button>
              </Modal.Body>
            </Modal>

          </div>
        )
    }
}
const mapStateToProps=(state)=>{
  return{
          customerInfo:state.customerInfo,
          customerInfoError: state.customerInfoError
      }
 
}
const mapDispatchToProps=(dispatch)=>{
  return {
    getCustomerInfo:bindActionCreators(getCustomerInfo,dispatch), 
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Cart)