import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class Orders extends React.Component {
    render() {
        let products=[{id:1,name:'Aaloo Tikki',total:32,date:new Date(),ship:'Me',status:'Completed'}]
        return (
                    <BootstrapTable data={products}   >
                        <TableHeaderColumn dataField='id' isKey>Order ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='date'>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='ship'>Ship To </TableHeaderColumn>
                        <TableHeaderColumn dataField='total'>Order Total </TableHeaderColumn>
                        <TableHeaderColumn dataField='status'>Status </TableHeaderColumn>
                        <TableHeaderColumn dataField=''>Action </TableHeaderColumn>
                    </BootstrapTable>
        )
    }
}

export default Orders