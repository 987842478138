import React from 'react'
import { TopCategory,AllFilters } from '../components/TopCategory'
import ProductList from '../components/ProductList' 
import {getAttributes,cartList} from '../services/customer'
import Api from '../Api'
import {connect} from 'react-redux'

class Products extends React.Component{
     state={
        categories:[],
        products:[],
        oldProducts:[],
        attributesData:[],
        filterArray:[]

     }
     handleProductFilter=(e)=>{
       let arr= [...this.state.filterArray]
       let fName= e.target.name.split('_');
       
       if(e.target.checked){
           arr.push({'code':fName[0],'value':fName[1]})
       }else{
           let indx = arr.findIndex(ar=>ar.code===fName[0] && ar.value===fName[1])
           if(indx >= 0){
               arr.splice(indx,1)
           }
       }
      
       //fileter products
       let products= {...this.state.oldProducts}
       let categories= {...this.state.categories}
       let newFilterArray={}
       if(arr.length >0 && products!==undefined){
         arr.map(flr=>{
             
              categories.children_data.map(cat=>{
                let tx = products[cat.name.toLowerCase()].length > 0 && products[cat.name.toLowerCase()].filter(it=>{
                    let ty= it.custom_attributes.filter(sbit=>sbit.attribute_code===flr.code && sbit.value===flr.value )
                    if(ty.length){
                          return it
                    }
                  
                })
               
                if(tx.length){
                    if(newFilterArray[cat.name.toLowerCase()]===undefined){
                       
                        newFilterArray[cat.name.toLowerCase()]=tx
                      
                    }else{
                        
                        let tmp=[...newFilterArray[cat.name.toLowerCase()],...tx]
                        newFilterArray[cat.name.toLowerCase()]=tmp
                    }
                    
                }
                
                
                
            })
           
              
        })
 
       }else{
        newFilterArray= {...this.state.oldProducts } 
       }
  
       this.setState({filterArray:arr,products:newFilterArray})

     }
     componentDidMount(){
        
        Promise.all([
            Api.get('SBV/V1/categories'),
            Api.get('SBV/V1/products?searchCriteria[pageSize]=2000'),
            getAttributes()
            
          ])
          .then(([categoriesData, productsData,attributesData]) => {
             
            let categoryProducts={}
            if(productsData.data.items!==undefined){
                categoriesData.data.children_data.map(cat=>{
                    categoryProducts[cat.name.toLowerCase()]= productsData.data.items.length && productsData.data.items.filter(item=>{
                     let tm = item.extension_attributes.category_links.filter(subItem=>subItem.category_id==cat.id)
                     return tm.length > 0 && item
                  })
                 
                })
             }
            this.setState({categories : categoriesData.data, products : categoryProducts,attributesData:attributesData,oldProducts:categoryProducts});
              }).catch(err=>console.log(err));
     }
    render(){
        //console.log(this.props.customerInfo)
        return(
            <React.Fragment>
                <TopCategory categories={this.state.categories}/>
                <AllFilters attributesData={this.state.attributesData} filterProduct={(e)=>this.handleProductFilter(e)}/>
                <ProductList products={this.state.products} categories={this.state.categories}  {...this.props}/>
            </React.Fragment>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
            customerInfo:state.customerInfo
        }
   
  }
export default connect(mapStateToProps)(Products)