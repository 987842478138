import React from 'react'
import {connect} from 'react-redux'


class CustomerProfile extends React.Component{
    render(){
        console.log(this.props.customerInfo)
        return(
            <div className="row">
                <div className="col-md-3 bg-red">
                    <ul className="nav navbar">
                        <li>Account Information</li>
                    </ul>
                </div>
                <div className="col-md-9">
                    
                </div>


            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
            customerInfo:state.customerInfo,
            customerInfoError: state.customerInfoError
        }
   
  }
  export default connect(mapStateToProps) (CustomerProfile)