import Api from '../../Api'

export const getCustomerToken=(data)=>{
    return (dispatch)=>{
        Api.post('/V1/integration/customer/token',data)
        .then(res=>{
            dispatch({
                type:'customer_token',
                payload:res.data
            })
        })
        .catch(err=>{
            dispatch({
                type:'customer_token_failed',
                payload:err
                
            })
        })
    }
}

export const createCustomer=(data)=>{
    let temp ={
        "customer": {
          "email": data.email,
          "firstname": data.firstname,
          "store_id":1,
          "website_id":1,
          "lastname": data.lastname,
          "addresses": [{
            "defaultShipping": true,
            "defaultBilling": true,
            "firstname": data.firstname,
            "lastname": data.lastname,
            "postcode": data.zip,
            "street": [data.address],
            "city": data.city,
            "telephone": data.phone,
            "countryId": "US"
          }]
        },
        "password": data.password
      }
    return (dispatch)=>{
        Api.post('/V1/customers',temp)
        .then(res=>{
            dispatch({
                type:'customer_created',
                payload:res.data
            })
        })
        .catch(err=>{
            dispatch({
                type:'customer_created_error',
                payload:err.status!==200 ? err.data.message:'Try again'
                
            })
        })
    }
}


export const getCustomerInfo=()=>{
    return (dispatch)=>{
        Api.get('/V1/customers/me')
        .then(res=>{
           
            dispatch({
                type:'customer_info',
                payload:res.data
            })
        })
        .catch(err=>{
            dispatch({
                type:'customer_info_failed',
                payload:err
                
            })
        })
    }
}