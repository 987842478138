import React from 'react';
import './App.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Routes from './Routes'

class App extends React.Component {
  render() {
    return (
     <Routes/>
    )
  }
}

export default (App)

