import React from 'react';
import deliveryTruck from '../assets/images/delivery-truck.png'
import shoppingBug from '../assets/images/shopping-bag.png'
import DeliveryForm from '../components/DeliveryForm';
import {PickupForm} from '../components/PickupForm';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getCustomerInfo} from '../stores/actions/customer.action'


class Catering extends React.Component{
  constructor(props){
    super(props)
    let d = new Date();
    this.state={
        deliverForm:false,
        pickupForm:false,
        customerInfo:{
          date:d.getFullYear()+'-'+("0" + (d.getMonth() + 1)).slice(-2) +'-'+("0" + d.getDate()).slice(-2),
          time:("0" + d.getHours()).slice(-2)+':'+("0" + d.getMinutes()).slice(-2)+':'+("0" + d.getSeconds()).slice(-2)
        }
    }
  }
    handleFormVisibale(v){
        if(v==='DeliveryForm'){
            this.setState({ deliverForm:true, pickupForm:false})
        }else if(v==='PickupForm'){
            this.setState({ deliverForm:false, pickupForm:true})
        }else{
            this.setState({ deliverForm:false, pickupForm:false})
        }
    }
    handleChange=(e)=>{
        let tempCustomerInfo ={...this.state.customerInfo}
        tempCustomerInfo[[e.target.name]]=e.target.value
        tempCustomerInfo['orderType']= this.state.deliverForm?'Delivery':'Pickup';
        this.setState({customerInfo:tempCustomerInfo})
    }
    componentWillReceiveProps(netxProps){
      if(netxProps.customerInfo!==undefined){
        this.setState({
          customerInfo:netxProps.customerInfo
        })
      }

    }
   componentDidMount(){
    if(localStorage.getItem('customerToken')){
      this.props.getCustomerInfo();
    }
    let temp= JSON.parse(localStorage.getItem('cartList'))
    if(temp!==null && temp.customer!==undefined){
      this.setState({customerInfo:temp.customer})
    }
      
   }
  render(){
    
    return(
        <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h2 className="mb-3">Order Catering Online</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat.</p>
              </div>
            </div>
            <div className="col-md-8 col-xs-8 m-auto">
              <div className="order-opt">
                <div className="delivery-opt" id="delivery">
                  <a onClick={()=>this.handleFormVisibale('DeliveryForm')}>
                    <img src={deliveryTruck} />
                  </a>
                  <h3 className="mt-3 t-color">DELIVERY</h3>
                </div>
                <div className="pick-up-opt" id="pick_up">
                  <a  onClick={()=>this.handleFormVisibale('PickupForm')}>
                    <img src={shoppingBug} />
                  </a>
                  <h3 className="mt-3 t-color">PICK-UP</h3>
                </div>
              </div>
                {this.state.deliverForm&&<DeliveryForm customer={this.state.customerInfo} onHandleChange={this.handleChange} />}
                {this.state.pickupForm&&<PickupForm customer={this.state.customerInfo} onHandleChange={this.handleChange}/>}
               
            </div>
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps=(state)=>{
  return{
          customerInfo:state.customerInfo,
          customerInfoError: state.customerInfoError
      }
 
}
const mapDispatchToProps=(dispatch)=>{
  return {
      getCustomerInfo:bindActionCreators(getCustomerInfo,dispatch), 
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Catering);
